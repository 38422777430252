import { template as template_9105dd7376bc44dfac7b69b6d914d425 } from "@ember/template-compiler";
const WelcomeHeader = template_9105dd7376bc44dfac7b69b6d914d425(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;

import { template as template_91c7909e4e2c4c44abc2d4e405254768 } from "@ember/template-compiler";
const FKText = template_91c7909e4e2c4c44abc2d4e405254768(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;

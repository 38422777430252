import { template as template_e6b17495c5e8458ab5004a47f81c18dd } from "@ember/template-compiler";
const FKControlMenuContainer = template_e6b17495c5e8458ab5004a47f81c18dd(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
